import React from "react"
import "./LoginLayout.css"

const LoginLayout = ({ children }) => {
  return (
    <div className="body">
      <header className="header">
        <a className="logo" href="/"> </a>
        <h2 className="title">Appsflow - APA Cloud Market Data</h2>
      </header>
      <main className="mainContainer">{children}</main>
    </div>
  )
}

export default LoginLayout
