import React from "react"

import "./CustomerForm.css"

export default class CustomerForm extends React.Component {
  state = {
    customers: [
      "New Customer",
      "AMPS",
      "BMJ",
      "Brit Insurance",
      "DataLab",
      "HSBC",
      "JLR",
      "John Lewis Partnership",
      "Propellant",
      "PwC",
      "Unipart",
    ],
    selectedCustomer: "",
  }

  handleCustomerChange = event => {
    this.setState({
      selectedCustomer: event.target.value,
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <h2>Home</h2>
          <div className="field">
            <label htmlFor="customer">Please setup your customer account</label>
            <select name="Accounts" id="customer" className="dropdown">
              {this.state.customers.map(option => (
                <option
                  value={option}
                  key={this.state.customers.indexOf(option)}
                >
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
