import React from "react"
import LoginLayout from "../components/LoginLayout"
import CustomerForm from "../components/CustomerForm";

const HomePage = () => (
  <LoginLayout>
    <CustomerForm />
  </LoginLayout>
)

export default HomePage
